import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@material-ui/core';

interface Props {
    open: boolean;
    handleClose: any;
    handleSubmit: any;
}

const FeedbackDialog = (props: Props) => {
    const [text, setText] = useState("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    }

    const handleSubmit = () => {
        props.handleSubmit(text)
    }

    return (
        <div>
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Feedback</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Find any bugs or errors? Got suggestions or feature requests? Let me know.
                </DialogContentText>
                <TextField
                    value={text}
                    onChange={handleChange}
                    autoFocus
                    margin="dense"
                    id="feedback"
                    //label="Email Address"
                    fullWidth
                    multiline
                    rows={3}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="default">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    )
}

export default FeedbackDialog;