import * as firebase from "firebase/app";
import app from '../base';
import "firebase/auth";

const provider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = async () => await app.auth().signInWithPopup(provider);

export const signInAnonymous = () => app.auth().signInAnonymously();

export const signOut = () => app.auth().signOut();