import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, TextField, Button } from '@material-ui/core';
import * as db from '../services/db';
import { UserContext } from '../components/Session';

const useStyles = makeStyles((theme) => ({
  root: {
      flexGrow: 1,
  },
  title: {
    marginTop: "1rem",
    marginBottom: "2rem"
  },
  button: {
      margin: "0.5rem 0"
  }
}));
 
const AccountPage = (props) => {
  const classes = useStyles();
  const user = useContext(UserContext);
  const [name, setName] = useState("");
  const [helpText, setHelpText] = useState("");

  useEffect(() => {
    if (user)
      setName(user.displayName!);
  }, [user])

  const changeField = (event) => {
    const name = event.target.value;
    setName(name);
  }

  const formSubmit = (event) => {
    event.preventDefault();
    
    const trimmedName = name.trim();
    if (trimmedName.length > 0) {
      user!.updateProfile({displayName: name})
        .then(async () => {
          await db.updateUserName(user!);
          setHelpText("Name updated successfully.")
        })
        .catch((error) => {
          setHelpText(error.message)
        })
    } else {
      setHelpText("Name is required.")
    }
  }

  return (
    <>
      {user &&
        <>
          <h2 className={classes.title}>Account Page</h2>
          <form onSubmit={formSubmit}>
            <TextField
              id="name"
              value={name}
              onChange={changeField}
              label="Name"
              variant="outlined"
              autoFocus
            />
            <div>
              <Button type="submit" variant="outlined" className={classes.button}>Change</Button>
            </div>
            { helpText &&
              <span>{helpText}</span>
            }
          </form>
        </>
      }
    </>
  );
}
 
 
export default AccountPage;