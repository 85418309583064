import React from 'react';
import * as models from "../../models";
import { Paper, makeStyles } from '@material-ui/core';
import { red, indigo, common, blue, grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    paper: {
        color: common["white"],
        fontSize: "large",
        minWidth: "135px",
        padding: theme.spacing(2),
        backgroundColor: indigo[500],
        textAlign: 'center',
        transition: '0.2s',
        '&:hover': {
            transform: 'scale(1.1)',
        },
    },
    selected: {
        transform: 'scale(1.2)',
        '&:hover': {
            transform: 'scale(1.2)',
        },
    },
    flipped: {
        opacity: 0.25
    },
    black: {
        background: common["black"]
    },
    blue: {
        background: blue[600]
    },
    grey: {
        backgroundColor: grey[600]
    },
    red: {
        background: red[600]
    }
}));

export interface Props {
    tile: models.Tile;
    selectedIndex: number;
}

const SpymasterTile = (props: Props) => {
    const classes = useStyles();
    const selected = props.selectedIndex === props.tile.index;

    const getClasses = () => {
        let paperClassList = [classes.paper, classes[props.tile.color]]
        if (selected)
            paperClassList.push(classes.selected)
        if (props.tile.flipped)
            paperClassList.push(classes.flipped)
        
        return paperClassList.join(' ');
    }

    return (
        <Paper key={props.tile.index} className={getClasses()}>
            <span>{props.tile.title}</span>
        </Paper>
    )
}

export default SpymasterTile;