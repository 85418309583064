import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as CONSTANTS from "../constants";
import TileBoard from "../components/Board/TileBoard";
import { Grid, Divider, makeStyles, Backdrop, Button, CircularProgress } from '@material-ui/core';
import { Player } from '../models';
import { convertFirebasePlayerstoTeams, convertFirebaseWordstoTiles } from '../components/Helpers/utility';
import Tile from '../models/Tile';
import * as db from '../services/db';
import SpymasterBoard from '../components/Board/SpymasterBoard';
import Turn from '../models/Turn';
import SimpleBackdrop from '../components/General/SimpleBackdrop';
import app from '../base';
import { UserContext } from '../components/Session';
import { useSnackbar } from 'notistack';
import { PlayerListWithIcons } from '../components/Lists';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
    },
}));

const Board = () => {
    let { id } = useParams();
    const user = useContext(UserContext);
    const classes = useStyles();
    let history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    
    const [loading, setLoading] = useState(true);
    const [tiles , setTiles] = useState(Array<Tile>());
    const [redTeam , setRedTeam] = useState(Array<Player>());
    const [blueTeam , setBlueTeam] = useState(Array<Player>());
    const [gameOver, setGameOver] = useState(false);
    const [currentPlayer, setCurrentPlayer] = useState<Player>();
    const [turn, setTurn] = useState<Turn>();
    const [winningTeam, setWinningTeam] = useState("");
    const [host, setHost] = useState(false);

    const roomRef = app.database().ref(CONSTANTS.ROOMS).child(id);

    useEffect(() => {
        roomRef.child(CONSTANTS.WORDS).on('value', snapshot => {
            if (snapshot.exists()) {
                if (gameOver) // short circuit
                    return null;

                const tiles = convertFirebaseWordstoTiles(snapshot.val());
                setTiles(tiles);
                setLoading(false);
            } else {
                setLoading(true);
            }
        });

        roomRef.child(CONSTANTS.BOARD).child(CONSTANTS.WINNING_TEAM).on('value', snapshot => {
            setWinningTeam(snapshot.val())
        })

        roomRef.child(CONSTANTS.BOARD).child(CONSTANTS.GAME_OVER).on('value', snapshot => {
            setGameOver(snapshot.val())
        })

        roomRef.child(CONSTANTS.BOARD).child(CONSTANTS.TURN).on('value', snapshot => {
            const turn: Turn = snapshot.val();
            setTurn(turn);
        })

        roomRef.child(CONSTANTS.BOARD).child(CONSTANTS.NEW_GAME).on('value', snapshot => {
            if (snapshot.exists()){
                try {
                    history.push(`/room/${snapshot.val()}`)
                } catch (error) {
                    console.error(error.message)
                }
            }    
        })

        roomRef.child(CONSTANTS.HOST).once('value', snapshot => {
            if (snapshot.val() === user!.uid) {
                setHost(true);
            }
        });

        if (user) {
            roomRef.child(CONSTANTS.PLAYERS).once('value', snapshot => {
                const fPlayers = snapshot.val();
                const players = convertFirebasePlayerstoTeams(fPlayers)

                setCurrentPlayer(players.find(p => p.id === user.uid))

                const blue = players.filter(p => p.team === CONSTANTS.BLUE)
                setBlueTeam(blue)

                const red = players.filter(p => p.team === CONSTANTS.RED)
                setRedTeam(red)
            })
        }

        // component unmount
        return () => {
            roomRef.child(CONSTANTS.WORDS).off();
            roomRef.child(CONSTANTS.BOARD).child(CONSTANTS.GAME_OVER).off();
            roomRef.child(CONSTANTS.BOARD).child(CONSTANTS.TURN).off();
            roomRef.child(CONSTANTS.BOARD).child(CONSTANTS.WINNING_TEAM).off();
            roomRef.child(CONSTANTS.BOARD).child(CONSTANTS.NEW_GAME).off();
        }
    }, [user])

    useEffect(() => {
        if (gameOver) {
            // flip tiles on client
            const flippedTiles = tiles.map(t => ({...t, flipped: true}))
            setTiles(flippedTiles);
            roomRef.child(CONSTANTS.WORDS).off();
        }
    }, [gameOver])

    const newGame = async () => {
        try {
            enqueueSnackbar('Creating new game...');

            var newGame = app.functions().httpsCallable('newGame-createFromExisting');

            const players = redTeam.concat(blueTeam);
            await newGame({ currentCode: id, players: players });
            closeSnackbar();
        }
        catch (err) {
            console.error(err.message);
        }
    }

    const getStatus = () => {
        if (gameOver)
            return (
                <>
                <SimpleBackdrop>
                    <Grid container justify="center">
                        <h1>Game Over.</h1>
                        <Grid item xs={12}></Grid>
                        <h1>{winningTeam} team won!</h1>
                    </Grid>
                </SimpleBackdrop>
                { !host && <p>Game Over. Host can start a new game.</p> }
                { host &&
                    <Button variant="contained" onClick={newGame}>Start New Game</Button>
                }
                </>
            )
            
        if (turn) {
            if (turn.type === "spymaster")
                return (<p>{turn.team} spymaster is thinking...</p>)
            else if (turn.type === "player")
                return (<p>{turn.team} team is guessing. Clue: {turn!.clue}. Guesses: {turn!.guesses}</p>)
        }
    }
    
    if (!gameOver) {
        if (tiles.filter(p => p.color === CONSTANTS.RED && p.flipped).length === tiles.filter(p => p.color === CONSTANTS.RED).length) {
            if (tiles.filter(p => p.color === CONSTANTS.RED && p.flipped).length > 7) {
                setGameOver(true)
                setWinningTeam(CONSTANTS.RED)
                db.gameOver(id, CONSTANTS.RED)
            }
                
        } else if (tiles.filter(p => p.color === CONSTANTS.BLUE && p.flipped).length === tiles.filter(p => p.color === CONSTANTS.BLUE).length) {
            if (tiles.filter(p => p.color === CONSTANTS.BLUE && p.flipped).length > 7) {
                setGameOver(true)
                setWinningTeam(CONSTANTS.BLUE)
                db.gameOver(id, CONSTANTS.BLUE)
            }
        }
    }

    if (loading)
        return (
            <Backdrop open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )

    return (
        <>
            <Grid container justify="center">
                <Grid item>
                    {getStatus()}
                </Grid>
            </Grid>
            { currentPlayer?.spymaster
                ? <SpymasterBoard tiles={tiles} currentPlayer={currentPlayer} />
                : <TileBoard tiles={tiles} currentPlayer={currentPlayer} gameOver={gameOver} />
            }
            <Grid container className={classes.root} justify="space-evenly" alignItems="stretch">
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Grid item>
                            <h4>Red Team: {tiles.filter(p => p.color === "red" && p.flipped).length} / {tiles.filter(p => p.color === "red").length}</h4>
                        </Grid>
                        <Grid item>
                            <PlayerListWithIcons players={redTeam.sort((a,b) => a===b? 0 : a.spymaster? -1 : 1)} page="board" />
                        </Grid>
                    </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Grid item>
                            <h4>Blue Team: {tiles.filter(p => p.color === "blue").filter(p => p.flipped).length} / {tiles.filter(p => p.color === "blue").length}</h4>
                        </Grid>
                        <Grid item>
                            <PlayerListWithIcons players={blueTeam.sort((a,b) => a===b? 0 : a.spymaster? -1 : 1)} page="board" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Board;