import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from "react-router-dom";
import * as CONSTANTS from "../constants";
import { Button, makeStyles } from '@material-ui/core';
import Player from '../models/Player';
import * as db from '../services/db';
import app from '../base';
import { UserContext } from '../components/Session';
import { PlayerList } from "../components/Lists";

const MIN_PLAYERS = 4

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 500,
    },
    title: {
        marginTop: "1rem",
        marginBottom: "2rem"
    },
    button: {
        marginRight: "1rem",
        margin: "0.5rem 0"
    }
}));

const Room = () => {
    let { id } = useParams();
    let history = useHistory();
    const classes = useStyles();
    const user = useContext(UserContext);
    const [host, setHost] = useState(false);
    const [players , setPlayers] = useState(Array<Player>());

    useEffect(() => {
        const roomRef = app.database().ref(CONSTANTS.ROOMS).child(id);

        roomRef.once('value', snapshot => {
            const room = snapshot.val();

            if (room[CONSTANTS.START] === true) {
                console.log("Game starting...");
                history.push(`/room/${id}/board`);
            } else if (room[CONSTANTS.FULL] === true) {
                console.log("Setup starting...");
                history.push(`/room/${id}/pick-teams`);
            }

            switch (room[CONSTANTS.STATE]) {
                case CONSTANTS.PICK_TEAMS:
                    history.push(`/room/${id}/pick-teams`);
                    break;
                case "spy-master":
                    history.push(`/room/${id}/spy-master`);
                    break;
            }

            // if player not in room, add to room
            const playersInRoom  = room[CONSTANTS.PLAYERS]
            if (!playersInRoom[user!.uid])
                db.joinRoom(id, user!)
        });

        roomRef.child(CONSTANTS.CREATED_ON).on('value', async snapshot => {
            if (!snapshot.exists())
                await db.removeRoomCodeFromPlayer(user!.uid)
                    .then(() => {
                        history.push('/')
                    })
        })

        roomRef.child(CONSTANTS.STATE).on('value', snapshot => {
            switch (snapshot.val()) {
                case CONSTANTS.PICK_TEAMS:
                    history.push(`/room/${id}/pick-teams`);
                    break;
                case "spy-master":
                    history.push(`/room/${id}/spy-master`);
                    break;
                case CONSTANTS.BOARD:
                    history.push(`/room/${id}/board`);
                    break;
            }
        })

        roomRef.child(CONSTANTS.HOST).on('value', snapshot => {
            const playerId = user!.uid;
            if (snapshot.val() === playerId) {
                setHost(true);
            }
        });

        roomRef.child(CONSTANTS.PLAYERS).on('value', snapshot => {
            const playerList = snapshot.val();
            setPlayers([]);
            for (let id in playerList) {
                const player: Player =
                    {
                        id: playerList[id].id,
                        name: playerList[id].name
                    }
                setPlayers(state => {
                    return [...state, player];
                })
            }
        })

        // component unmount
        return () => {
            roomRef.child(CONSTANTS.STATE).off();
            roomRef.child(CONSTANTS.HOST).off();
            roomRef.child(CONSTANTS.PLAYERS).off();
        }
    }, [user])

    const deleteGame = async () => {
        await db.deleteGame(id, players).then(() => {
            history.push("/");
        })
    }

    const leaveGame = async () => {
        await db.leaveGame(id, user!.uid).then(() => {
            history.push("/");
        })
    }

    const startGameClicked = async () => {
        if (players.length >= MIN_PLAYERS && host) {
            try {
                await db.startGame(id)
            }
            catch (error) {
                console.log(error.message);
            }
        }
    }

    let startGameButton;
    if (players.length >= MIN_PLAYERS) {
        if (host) {
            startGameButton = <Button onClick={startGameClicked} className={classes.button} variant="contained">Start Game</Button>
        } else {
            startGameButton = <div><span>Waiting for host to start game</span></div>
        }

    } else {
        startGameButton = <div><span>Waiting for { MIN_PLAYERS - players.length } more players...</span></div>
    }

    return (
        <>
            { players &&
                <>
            <h1 className={classes.title}>Room - {id}</h1>
                    <div className={classes.root}>
            <PlayerList players={players}/>
                    </div>
            { startGameButton }
                    { host
                        ?
                            <Button onClick={deleteGame} className={classes.button} variant="outlined">Delete Game</Button>
                        :
                            <Button onClick={leaveGame} className={classes.button} variant="outlined">Leave</Button>
                    }
                </>
            }
        </>
    );
}

export default Room;