import React from 'react';
import { makeStyles, Card, CardActionArea, CardHeader, Typography, CardContent } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      boxSizing: "border-box",
      flexShrink: 0,
      height: "100%",
      width: 300,
      //padding: 6,
      //width: "calc(50% - 10px)"
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
    action: {
        height: "100%"
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    header: {
      height: 100,
    },
  }));

interface Props {
  headerText: string;
  contentText: string;
}

const WelcomeCard = (props: Props) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea className={classes.action}>
                <CardHeader
                className={classes.header}
                />
                <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                        {props.headerText}
                    </Typography>
                    <Typography variant="body2" component="p">
                        {props.contentText}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default WelcomeCard;