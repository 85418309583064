import React, {FunctionComponent, useState, useEffect, useContext} from 'react';
import { Redirect, useParams } from 'react-router-dom';
import app from './base'
import { Backdrop, CircularProgress } from '@material-ui/core';
import { ROOMS, PLAYERS } from './constants';
import { UserContext } from './components/Session';

type RoomRouteProps = {
    checkRoom?: boolean;
    checkPlayer?: boolean;
    children: any;
}

const PrivateRoomRoute: FunctionComponent<RoomRouteProps> = (props) => {
    const { id } = useParams();
    const user = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [allowAccess, setAllowAccess] = useState(false);

    useEffect(() => {
        setLoading(true);

        if (props.checkRoom) {
            const checkRoom = async () => {
                await app.database().ref(ROOMS).child(id).once('value')
                    .then((snapshot) => {
                        setAllowAccess(snapshot.exists());
                        setLoading(false);
                    })
            }
            checkRoom();
        }
        
        if (props.checkPlayer) {
            const checkPlayer = async () => {
                await app.database().ref(ROOMS).child(id)
                    .child(PLAYERS).child(user!.uid).once('value')
                    .then((snapshot) => {
                        setAllowAccess(snapshot.exists())
                        setLoading(false);
                    })
            }
            checkPlayer()
        }
    }, [user, id, props])

    if (loading) {
        return (
            <Backdrop open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    } else {
        if (allowAccess)
            return (
                <>{props.children}</>
            )
        else 
            return <Redirect to={"/page-not-found"} />
    }
}

export default PrivateRoomRoute;