import React, { useState, useContext } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { AppBar, Toolbar, makeStyles, IconButton, Menu, MenuItem, Link} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { signOut } from '../services/auth';
import { UserContext } from './Session';

const Navigation = () => {
  const user = useContext(UserContext);

  return (
    <>
    { user && <AuthNavigation /> }
    </>
  )
}

const AuthNavigation = (props) => {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = () => {
    handleClose();
    signOut().then(() => {
      history.push("/");
    });
  }

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} href="/account" component="a">Profile</MenuItem>
        <MenuItem onClick={handleSignout}>Signout</MenuItem>
      </Menu>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link: {
    '&:hover': {
      textDecoration: "none"
    },
  }
}));

export default () => {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Link component={RouterLink} to="/" color="inherit" className={classes.link}>Codenames</Link>
          <span className={classes.title}></span>
          <Navigation />
        </Toolbar>
      </AppBar>
    </div>
  );
}