import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import { config } from "./firebase-config";

const app = firebase.initializeApp(config);
export const analytics = firebase.analytics();
export const functions = firebase.functions();

export default app;