import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Grid, TextField, Button, makeStyles } from '@material-ui/core';
import * as db from '../services/db';
import { UserContext } from '../components/Session';

const Join = () => (
    <div>
        <h1>Join</h1>
        <JoinForm />
    </div>
);

const useStyles = makeStyles((theme) => ({
    button: {
        margin: "0.5rem 0"
    }
}));

const JoinForm = () => {
    let history = useHistory();
    const classes = useStyles();
    const [code, setCode] = useState("");
    const [error, setError] = useState("");
    const user = useContext(UserContext);

    const changeField = (event) => {
        setCode(event.target.value);
    }

    const formSubmit = async (event) => {
        event.preventDefault();

        if (code !== "" && code.length > 0) {
            try {
                await db.joinRoom(code, user!).then(() => {
                    history.push(`room/${code}`);
                });
            }
            catch (err) {
                setError(err.message);
            }
        }
    }
    
    return (
        <Grid container>
            <Grid item xs={12}>
                <form onSubmit={formSubmit}>
                    <TextField
                        id="code"
                        value={code}
                        onChange={changeField}
                        label="Enter game code"
                        variant="outlined"
                    />
                    <div>
                        <Button type="submit" variant="outlined" className={classes.button}>Join</Button>
                    </div>
                    { error &&
                        <span>{error}</span>
                    }
                </form>
            </Grid>
        </Grid>
    )
}

export default Join;