import React, { useState, useEffect } from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import Tile from './Tile';
import { useParams } from 'react-router-dom';
import * as db from '../../services/db';
import * as models from "../../models";
import * as CONSTANTS from "../../constants";
import {  Turn } from '../../models';
import app from '../../base';

const useStyles = makeStyles({
    root: {
        marginTop: 16,
    },
    button: {
        margin: "0.5rem 0"
    }
});

interface Props {
    tiles: models.Tile[];
    currentPlayer?: models.Player;
    gameOver: boolean;
}

const TileBoard = ({ tiles, currentPlayer, gameOver }: Props) => {
    let { id } = useParams();
    const classes = useStyles();
    // const [tiles , setTiles] = useState(Array<models.Tile>());
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [isEnabled, setIsEnabled] = useState(false);
    const [turn, setTurn] = useState<Turn>();

    // useEffect(() => {
    //     const roomRef = firebase.database().ref(CONSTANTS.ROOMS).child(id);
    //     roomRef.child(CONSTANTS.WORDS).on('value', snapshot => {
    //         if (snapshot.exists()) {
    //             const tiles = convertFirebaseWordstoTiles(snapshot.val());
    //             setTiles(tiles);
    //         } else {
    //             getWords(id);
    //         }
    //     });
    // }, [])

    useEffect(() => {
        const roomRef = app.database().ref(CONSTANTS.ROOMS).child(id);

        if (currentPlayer){
            roomRef.child("board").child("turn").on('value', snapshot => {
                if (snapshot.exists()) {
                    const turn: Turn = snapshot.val();
                    setTurn(turn)
                    setIsEnabled(turn.type === "player" && turn.team === currentPlayer.team)
                    setSelectedIndex(turn.selected)
                }
            })
        }

        // component unmount
        return () => {
            roomRef.child("board").child("turn").off();
        }
    }, [currentPlayer])

    const handleClick = async (index) => {
        if (isEnabled) {
            await db.selectTile(id, index);
        }
    }

    const handleFlip = async (index) => {
        if (isEnabled) {
            try {
                await db.tileSelectedInDB(id, index, turn!);
            }
            catch (error)
            {
                console.error(error.message)
            }
        }
    }

    const endTurnClicked = async () => {
        try {
            await db.endTurn(id, turn!);
        }
        catch (error)
        {
            console.error(error.message)
        }
    }

    return (
        <>
            <Grid container spacing={3} justify="center" className={classes.root}>
                {tiles.map((tile, index) =>
                    <Grid item key={index}>
                        <Tile
                            tile={tile}
                            selectedIndex={selectedIndex}
                            handleClick={handleClick}
                            handleFlip={handleFlip}
                            isEnabled={isEnabled}
                        />
                    </Grid>
                )}
            </Grid>
            { (isEnabled) && 
                <Grid container spacing={2} justify="center">
                    <Grid item>
                        <Button type="submit" variant="contained" size="large"
                            color="primary" className={classes.button}
                            onClick={endTurnClicked}>
                                End Turn
                        </Button>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default TileBoard;