import * as CONSTANTS from "../../constants";
import Player from "../../models/Player";
import Tile from "../../models/Tile";

const convertFirebasePlayerstoTeams = (fPlayers) => {
    let players: Array<Player> = [];
    for (const pid in fPlayers) {
        let player = fPlayers[pid]
        players.push({
            id: pid,
            name: player[CONSTANTS.NAME],
            team: player[CONSTANTS.TEAM],
            spymaster: player[CONSTANTS.SPYMASTER]
                ? player[CONSTANTS.SPYMASTER]
                : false
        })
    }
    return players;
}

const convertFirebaseWordstoTiles = (fWords) => {
    let tiles: Array<Tile> = [];
    for (const pid in fWords) {
        const tile = fWords[pid]
        tiles.push({
            title: tile["title"],
            color: tile["color"],
            flipped: tile["flipped"],
            index: parseInt(pid)
        })
    }
    return tiles;
}

// const convertFirebaseTeamstoTeams = (team) => {
//     let teams :Array<Player> = [];
//     for (const pid in team) {
//         teams.push({id: pid, name: team[pid]})
//     }
//     return teams;
// }

export { convertFirebasePlayerstoTeams, convertFirebaseWordstoTiles };

