import React, { useState, useContext } from 'react';
import { Grid, Button, Divider, makeStyles } from '@material-ui/core';
import FeedbackDialog from './General/FeedbackDialog';
import { logFeedback } from '../services/db';
import { UserContext } from './Session';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    footer: {
    },
    root: {
        padding: "1rem 0",
    },
    item: {
        margin: "0 1rem"
    }
}));

const Footer = () => {
    const user = useContext(UserContext);
    let history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const openDialog = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSubmit = (text: string) => {
        logFeedback(user!.uid, text, history.location.pathname)
        handleClose()
    }

    return (
        <footer className={classes.footer} >
            <Divider />
            <Grid container alignItems="center" justify="center" className={classes.root}>
                <Grid item className={classes.item}>
                    <span>Developed by Dharmesh Kamdar</span>
                    {/* <span>Copyright © {new Date().getFullYear()} Dharmesh Kamdar</span> */}
                </Grid>
                <Grid item className={classes.item}>
                    <Button onClick={openDialog}>Got Feedback?</Button>
                </Grid>
            </Grid>
            <FeedbackDialog open={open} handleClose={handleClose} handleSubmit={handleSubmit} />
        </footer>
    )
}

export default Footer;