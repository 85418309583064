import React, { FunctionComponent } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

type Props = {
}

const SimpleBackdrop: FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
            {props.children}
        </Backdrop>
    );
}

export default SimpleBackdrop;