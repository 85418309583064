import React, { useState, useEffect } from 'react'
import { TextField, FormControl, InputLabel, Select, MenuItem, Button, Theme, createStyles, makeStyles, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

const SpymasterControlElements = ({onSubmit}) => {
    const classes = useStyles();
    const [clue, setClue] = useState("");
    const [guesses, setGuesses] = useState(0);

    const changeField = (event) => {
        const clue = event.target.value;
        setClue(clue);
    }

    const changeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        setGuesses(parseInt(event.target.value as string));
    }

    const submitControls = () => {
        onSubmit(clue, guesses)
    }

    return (
        <Grid container justify="center" alignItems="center" spacing={2}>
            <Grid item>
                <TextField
                    id="clue"
                    value={clue}
                    onChange={changeField}
                    label="Enter your clue"
                    variant="outlined"
                />
            </Grid>
            <Grid item>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="guesses-label"># Guesses</InputLabel>
                    <Select
                        labelId="guesses-label"
                        id="guesses"
                        value={guesses}
                        onChange={changeSelect}
                        label="# Guesses"
                    >
                        {[...Array(9)].map((x, i) =>
                            <MenuItem key={i+1} value={i+1}>{i+1}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <Button type="submit" variant="outlined"
                    onClick={submitControls}>
                        Send
                </Button>
            </Grid>
        </Grid>
    )
}

const SpymasterControls = ({show, handleSubmit}) => {
    const [myTurn, setMyTurn] = useState(false);

    useEffect(() => {
        setMyTurn(show)
    }, [show])

    return (
        <>
            { myTurn && <SpymasterControlElements onSubmit={handleSubmit} />}
        </>
    )
}

export default SpymasterControls;