import React from 'react';
import { Route } from 'react-router';
import { Switch, Link } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import SignUp from './pages/SignUp';

import './custom.css'
import Account from './pages/Account';
import Join from './pages/Join';
import Room from './pages/Room';
import SpyMaster from './pages/SpyMaster';
import PickTeams from './pages/PickTeams';
import Board from './pages/Board';
import PrivateRoute from './PrivateRoute';
import PrivateRoomRoute from './PrivateRoomRoute';

export const NoMatch = () => {
    return(
        <div>
            <h1>Page not found</h1>
            <p style={{textAlign:"center"}}>
              <Link to="/">Return Home</Link>
            </p>
        </div>
    )
}

const Routes = () => {
    const room = "room";

    return (
        <Switch>
            <Route exact path='/login'>
                <Login />
            </Route>
            <Route exact path='/signup'>
                <SignUp />
            </Route>
            {/* Authenticated Users Only */}
            <PrivateRoute exact path='/account'>
                <Account />
            </PrivateRoute>
            <PrivateRoute exact path='/join'>
                <Join />
            </PrivateRoute>
            {/* If room exists */}
            <PrivateRoute exact path={`/${room}/:id`}>
                <PrivateRoomRoute checkRoom>
                    <Room />
                </PrivateRoomRoute>
            </PrivateRoute>
            {/* Users in Room {id} Only */}
            <PrivateRoute exact path={`/${room}/:id/pick-teams`}>
                <PrivateRoomRoute checkPlayer>
                    <PickTeams />
                </PrivateRoomRoute>
            </PrivateRoute>
            <PrivateRoute exact path={`/${room}/:id/spy-master`}>
                <PrivateRoomRoute checkPlayer>
                    <SpyMaster />
                </PrivateRoomRoute>
            </PrivateRoute>
            <PrivateRoute exact path={`/${room}/:id/board`}>
                <PrivateRoomRoute checkPlayer>
                    <Board />
                </PrivateRoomRoute>
            </PrivateRoute>
            {/* Base routes */}
            <Route exact path='/'>
                <Home />
            </Route>
            <Route path="*">
                <NoMatch /> {/* TODO - create custom 404 page*/}
            </Route>
        </Switch>
    )
}

export default Routes;