import React from 'react';
import { makeStyles, Card, CardActionArea, CardHeader, Typography, CardContent } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 200,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
  }));

interface Props {
  headerText: string;
  contentText: string;
}

const SimpleCard = (props: Props) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                        {props.headerText}
                    </Typography>
                    <Typography variant="body2" component="p">
                        {props.contentText}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default SimpleCard;