import React, {FunctionComponent, useContext} from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { UserContext } from './components/Session';

type Props = {
    path: string,
    exact?: boolean;
}

const PrivateRoute: FunctionComponent<Props> = (props) => {
    const user = useContext(UserContext);
    let history = useHistory();

    if (user) {
        return (
            <Route path={props.path} exact={props.exact}>
                {props.children}
            </Route>
        )
    } else {
        return (
            <Redirect to={{pathname: "/signup", state: { from: history.location }}} />
        )
    }
}

export default PrivateRoute;