import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Layout } from './components/Layout';
import Routes from './Routes';
//import './custom.css'
import { UserContext } from './components/Session';
import { useAuthState } from 'react-firebase-hooks/auth';
import app from './base';
import { Backdrop, CircularProgress, ThemeProvider, createMuiTheme, CssBaseline, responsiveFontSizes } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

const App = () => {
  const [user, loading] = useAuthState(app.auth());

  if (loading)
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )

  let darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
    },
    typography: {
      fontSize: 16
    }
  });
  darkTheme = responsiveFontSizes(darkTheme);

  return (
    <UserContext.Provider value={user}>
      <ThemeProvider theme={darkTheme}>
        <SnackbarProvider maxSnack={3}>
          <CssBaseline />
          <BrowserRouter>
            <Layout>
              <Routes />
            </Layout>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </UserContext.Provider>
  );
}

export default App;