// Firebase database constants

// Firebase nodes
export const FEEDBACK = "feedback";
export const PLAYERS = "players";
export const ROOMS = "rooms";
export const WORDS = "words";

// players constants
export const LAST_LOGIN = "lastLogin";

// words constants
export const STANDARD = "standard";

// rooms constants
export const BOARD = "board";
export const CREATED_ON = "createdOn";
export const FULL = "full";
export const HOST = "host";
export const START = "start";
export const STATE = "state";
export const TEAMS = "teams";

// room state constants
//export const BOARD = "board";
export const SETUP = "setup";
export const PICK_TEAMS = "pick-teams";
//export const SPYMASTER = "spy-master";

// board constants (rooms -> board)
export const GAME_OVER = "gameOver";
export const NEW_GAME = "newGame";
export const TURN = "turn";
export const WINNING_TEAM = "winningTeam";

// turn constants (rooms -> board -> turn)
export const CLUE = "clue";
export const GUESSES = "guesses";
//export const SELECTED = "selected";
export const TYPE = "type";

// room players constants (rooms -> players)
export const SPYMASTER = "spymaster";

// room words constants (rooms -> words)
export const COLOR = "color";
export const FLIPPED = "flipped";
//export const SELECTED = "selected";
export const TITLE = "title";

// tile color constants (rooms -> players -> color)
export const RED = "red";
export const BLUE = "blue";
export const GREY = "grey";
export const BLACK = "black";

// Generic
export const NAME = "name";
export const CODE = "code";
export const TEAM = "team";
export const SELECTED = "selected"; // TODO - change