import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import SpymasterControls from './SpymasterControls';
import { useParams } from 'react-router-dom';
import * as CONSTANTS from '../../constants';
import * as db from '../../services/db';
import SpymasterTile from './SpymasterTile';
import * as models from "../../models";
import app from '../../base';


const useStyles = makeStyles({
    root: {
        marginTop: 16,
    },
});

interface Props {
    tiles: models.Tile[];
    currentPlayer: models.Player;
}

const SpymasterBoard = ({ tiles, currentPlayer }: Props) => {
    let { id } = useParams();
    const classes = useStyles();
    const [myTurn, setMyTurn] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    useEffect(() => {
        const roomRef = app.database().ref(CONSTANTS.ROOMS).child(id);
        roomRef.child("board").child("turn")
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    const turn: models.Turn = snapshot.val();
                    if (turn.team === currentPlayer.team && turn.type === "spymaster")
                        setMyTurn(true)
                    else
                        setMyTurn(false)

                    setSelectedIndex(turn.selected)   
                }
            })
        
        // component unmount
        return () => {
            roomRef.child("board").child("turn").off();
        }

    }, [])

    const handleSubmit = async (clue: string, guesses: number) => {
        await db.spymasterSubmit(id, currentPlayer, clue, guesses)
    }

    return (
        <>
        <Grid container spacing={3} justify="center" className={classes.root}>
            {tiles.map((tile, index) =>
                <Grid item key={index}>
                    <SpymasterTile tile={tile} selectedIndex={selectedIndex} />
                </Grid>
            )}
            
        </Grid>
        <Grid container spacing={4} justify="center" className={classes.root} >
        <SpymasterControls show={myTurn} handleSubmit={handleSubmit} />
        </Grid>
        </>
    )
}

export default SpymasterBoard;