import React, { useEffect, useState } from 'react';
import { Paper, makeStyles } from "@material-ui/core";
import { grey, red, blue, common } from '@material-ui/core/colors';
import * as models from "../../models";
import ReactCardFlip from "react-card-flip";

const useStyles = makeStyles((theme) => ({
    paper: {
        color: common["black"],
        fontSize: "large",
        minWidth: "135px",
        padding: theme.spacing(2),
        backgroundColor: common["white"],
        textAlign: 'center',
        transition: '0.2s',
        '&:hover': {
            cursor: "pointer",
            transform: 'scale(1.1)',
        },
    },
    selected: {
        transform: 'scale(1.2)', // highlight
        backgroundColor: grey[300],
        '&:hover': {
            transform: 'scale(1.2)', // continue scale on hover
        },
    },
    flipped: {
        color: common["white"], // opposite
        '&:hover': {
            cursor: "auto" // disable click
        },
    },
    black: {
        background: common["black"]
    },
    blue: {
        background: blue[600]
    },
    grey: {
        backgroundColor: grey[600]
    },
    red: {
        background: red[600]
    }
}));

export interface Props {
    tile: models.Tile;
    selectedIndex: number;
    handleClick: (index: number) => void;
    handleFlip: (index: number) => void;
    isEnabled: boolean;
}

const Tile = (props: Props) => {
    const classes = useStyles();
    const [selected, setSelected] = useState(false);
    const [flipped, setFlipped] = useState(props.tile.flipped);
    const [isEnabled, setIsEnabled] = useState(props.isEnabled);

    useEffect(() => {
        setSelected(props.selectedIndex === props.tile.index)
    },[props.selectedIndex])


    useEffect(() => {
        setSelected(false)
        setFlipped(props.tile.flipped)
    }, [props.tile])

    useEffect(() => {
        setIsEnabled(props.isEnabled)
    }, [props.isEnabled])

    const handleTileClick = () => {
        if (!isEnabled)
            return;

        if (flipped)
            return;
        if (props.tile.index === props.selectedIndex) {
            if (!flipped){
                setFlipped(true)
                props.handleFlip(props.tile.index!)
            }
        } else {
            if (!flipped && !selected)
                setSelected(true)
        }

        props.handleClick(props.tile.index!);
    }

    const getUnflippedClasses = () => {
        let paperClassList = [classes.paper]
        if (selected)
            paperClassList.push(classes.selected)
        
        return paperClassList.join(' ');
    }

    const getFlippedClasses = () => {
        let paperClassList = [classes.paper]
        if (flipped){
            paperClassList.push(classes.flipped)
            paperClassList.push(classes[props.tile.color])
        }
        
        return paperClassList.join(' ');
    }

    return (
        <div
            key={props.tile.index}
            data-testid={"tile-" + props.tile.index}
            onClick={handleTileClick}
        >
            <ReactCardFlip isFlipped={flipped}>
                <Paper
                    className={getUnflippedClasses()}
                >
                    {props.tile.title}
                </Paper>
                <Paper
                    className={getFlippedClasses()}
                >
                    {props.tile.title}
                </Paper>
            </ReactCardFlip>
        </div>
    )
}

export default Tile;