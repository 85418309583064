import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button } from '@material-ui/core';
import { signInWithGoogle } from '../services/auth';

const LoginPage = () => (
    <div>
      <h1>Login</h1>
      <Login />
    </div>
);

const Login = () => {
    let history = useHistory();
    const [error, setError] = useState("");

    const googleSignIn = () => {
        signInWithGoogle()
            .then(result => {
                const user = result.user;
                if (user) {
                    history.push("/");
                }
            })
            .catch(error => {
                setError(error.message);
            });
    }

    return (
        <>
            <Button onClick={googleSignIn} variant="outlined">Google</Button>
            { error &&
                <div><span>{error}</span></div>
            }
        </>
    );
}

export default LoginPage;