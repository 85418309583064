import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as CONSTANTS from "../constants";
import { makeStyles, Grid, Divider, Button } from '@material-ui/core';
import Player from '../models/Player';
import * as db from '../services/db';
import app from '../base';
import { UserContext } from '../components/Session';
import { PlayerListWithIcons } from '../components/Lists';

const convertFirebasePlayerstoTeams = (fPlayers) => {
    let players: Array<Player> = [];
    for (const pid in fPlayers) {
        let player = fPlayers[pid]
        players.push({
            id: pid,
            name: player[CONSTANTS.NAME],
            team: player[CONSTANTS.TEAM],
            spymaster: player[CONSTANTS.SPYMASTER] ? player[CONSTANTS.SPYMASTER] : false
        })
    }
    return players;
}

const useStyles2 = makeStyles((theme) => ({
    root: {
        marginTop: 16,
    },
    button: {
        margin: theme.spacing(2),
    },
}));

const SpyMaster = () => {
    let { id } = useParams();
    let history = useHistory();
    const user = useContext(UserContext);
    const [host, setHost] = useState(false);
    const [redTeam , setRedTeam] = useState(Array<Player>());
    const [blueTeam , setBlueTeam] = useState(Array<Player>());
    const [loading, setLoading] = useState(false);
    const classes = useStyles2();

    const roomRef = app.database().ref(CONSTANTS.ROOMS).child(id);

    useEffect(() => {
        setLoading(true);

        roomRef.child(CONSTANTS.STATE).on('value', snapshot => {
            const room = snapshot.val();
            switch (room) {
                case "board":
                    history.push(`/room/${id}/board`);
                    break;
            }
        });

        roomRef.child(CONSTANTS.HOST).on('value', snapshot => {
            const playerId = user!.uid;
            if (snapshot.val() === playerId) {
                setHost(true);
            }
        });

        roomRef.child(CONSTANTS.PLAYERS).on('value', snapshot => {
            const fPlayers = snapshot.val();
            const players = convertFirebasePlayerstoTeams(fPlayers)

            const blue = players.filter(p => p.team === CONSTANTS.BLUE)
            setBlueTeam(blue)

            const red = players.filter(p => p.team === CONSTANTS.RED)
            setRedTeam(red)

            setLoading(false);
        })

        // component unmount
        return () => {
            roomRef.child(CONSTANTS.STATE).off();
            roomRef.child(CONSTANTS.HOST).off();
            roomRef.child(CONSTANTS.PLAYERS).off();
        }
    },[user])

    const setSpymaster = () => {
        const playerId = user!.uid;
        roomRef.child(CONSTANTS.PLAYERS).child(playerId)
            .child(CONSTANTS.SPYMASTER).set(true);
    }

    const unsetSpymaster = () => {
        const playerId = user!.uid;
        roomRef.child(CONSTANTS.PLAYERS).child(playerId)
            .child(CONSTANTS.SPYMASTER).remove();
    }

    const canGoToNextPage = () => {
        const blueTeamSpymasterCount = blueTeam.filter(e => e.spymaster === true).length;
        const redTeamSpymasterCount = redTeam.filter(e => e.spymaster === true).length;

        // if (blueTeamSpymasterCount === 0 || redTeamSpymasterCount === 0) {
        //     if (blueTeamSpymasterCount === 0 && redTeamSpymasterCount === 0)
        //         setError("Both teams need to pick spymasters")
        //     else if (blueTeamSpymasterCount === 0)
        //         setError("Blue team needs to pick spymaster")
        //     else if (redTeamSpymasterCount === 0)
        //         setError("Red team needs to pick spymaster")
        // }

        // if (blueTeamSpymasterCount > 1 || redTeamSpymasterCount > 1) {
        //     if (blueTeamSpymasterCount > 1 && redTeamSpymasterCount > 1)
        //         setError("Each team can only pick 1 spymaster")
        //     else if (blueTeamSpymasterCount > 1)
        //         setError("Blue team can only pick 1 spymaster")
        //     else if (redTeamSpymasterCount === 0)
        //         setError("Red teams can only pick 1 spymaster")
        // }

        return blueTeamSpymasterCount === 1 && redTeamSpymasterCount === 1;
    }

    const startGame = async () => {
        if (canGoToNextPage()) {
            await db.setBoard(id);
            await roomRef.child(CONSTANTS.START).set(true);
            await roomRef.child(CONSTANTS.STATE).set(CONSTANTS.BOARD);
            history.push(`/room/${id}/board`);
        }
    }

    let nextPageButton;
    if (canGoToNextPage()) {
        nextPageButton = <Button onClick={startGame} variant="contained">Next</Button>
    }
    
    return (
        <div className={classes.root}>
            <h3>Pick Spymaster</h3>
            { loading
                ? <p>Loading...</p>
                :
                    <Grid container spacing={2} justify="space-evenly">
                        <Grid item>
                            <p>Red Team</p>
                            <PlayerListWithIcons players={redTeam} page="spymaster"/>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item>
                            <p>Blue Team</p>
                            <PlayerListWithIcons players={blueTeam} page="spymaster"/>
                        </Grid>
                        <Grid container justify="center">
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={setSpymaster}
                                size="large"
                                startIcon={<span role="img" aria-label="Person raising hand">🙋</span>}
                            >
                                Yes
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={unsetSpymaster}
                                size="large"
                                startIcon={<span role="img" aria-label="Person crossing hands">🙅</span>}
                            >
                                No
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            { host && nextPageButton }
                            { (!host && canGoToNextPage()) && <>Waiting for host...</> }
                            { (!host && !canGoToNextPage()) && <>Need only 1 spymaster from each team before proceeding.</>}
                        </Grid>
                    </Grid>
            }
        </div>
    )
}

export default SpyMaster;