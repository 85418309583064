import app from '../base';
import { User } from 'firebase';
import * as CONSTANTS from "../constants";
import Player from '../models/Player';
import Room from '../models/Room';
import Tile from '../models/Tile';

// Accounts

export const setUserName = async (user: User) => {
  const updates = {};
  updates[CONSTANTS.NAME] = user.displayName;
  updates[CONSTANTS.LAST_LOGIN] = new Date().toISOString();

  await app.database().ref(CONSTANTS.PLAYERS).child(user.uid)
    .update(updates)
}

export const updateUserName = async (user: User) => {
  await setUserName(user).then(async () => {
    await setUserNameInGame(user);
  })
}

const setUserNameInGame = async (user: User) => {
  await app.database().ref(CONSTANTS.PLAYERS).child(user.uid)
  .child(CONSTANTS.CODE).once("value")
  .then((snapshot) => {
    if (snapshot.exists()){
      const roomId = snapshot.val()
      const update = {};
      update[CONSTANTS.NAME] = user.displayName!
      app.database().ref(CONSTANTS.ROOMS).child(roomId)
        .child(CONSTANTS.PLAYERS).child(user.uid)
        .update(update)
        .catch((error) => {
          throw new Error(error);
        })
    }
  }).catch(error => {
    throw new Error(error)
  })
}

// Join game

export const joinRoom = async (code: any, user: User) => {
  const roomRef  = app.database().ref(CONSTANTS.ROOMS).child(code);
  const playersRef = app.database().ref(CONSTANTS.PLAYERS);

  await roomRef.once('value')
    .then((snapshot) => {
      if (!snapshot.exists()) {
        // if no such room
        throw new Error(`"${code}" does not exist`);
      }
      return snapshot.val();
    })
    .then(async (room) => {
      await roomRef.child(CONSTANTS.PLAYERS).child(user.uid)
        .once('value', snapshot => {
          // if player NOT already in game and game has started
          if (!snapshot.exists() && room.start)
            throw new Error("Game has already started. You cannot join.");
        })
    })
    .then(async () => {
      const playerId = user!.uid;
      if (playerId) {
        // check with game player is currently in
        await playersRef.child(playerId).once('value')
          .then(async (snapshot) => {
            const currentGameCode = snapshot.child(CONSTANTS.CODE).val()
            if (currentGameCode === code){
              console.log("User is already part of the game. Reroute.")
            } else {
              // add player to game
              const playerName = user!.displayName;
              await roomRef.child(CONSTANTS.PLAYERS).child(playerId)
                .child(CONSTANTS.NAME).set(playerName);
            }
            // add game to player
            await playersRef.child(playerId).child(CONSTANTS.CODE).set(code);
          });
      } else {
        throw new Error(`Could not identify user.`);
      }
    })
    .catch((error) => {
      throw new Error(error.message);
    });
}

// New game

export const createRoom = async (user: User) => {
  const playerId = user!.uid;

    if (playerId) {
      // check is code is unique
      let gameCode = await getCodeForRoom()
        .then(async (code: string) => {
          await app.database().ref(CONSTANTS.PLAYERS).child(playerId).once('value')
            .then((snapshot) => {
              const currentGameCode = snapshot.child(CONSTANTS.CODE).val()
              if (currentGameCode){
                console.log("User is playing another game. Switching game.")
              }
            });

          const playerName = user!.displayName;

          const newRoom: Room = {
            host: playerId,
            state: CONSTANTS.SETUP,
            full: false,
            start: false,
            players: [],
            createdOn: new Date().toISOString()
          };
          app.database().ref(CONSTANTS.ROOMS).child(code).set(newRoom);
          
          app.database().ref(CONSTANTS.ROOMS).child(code)
            .child(CONSTANTS.PLAYERS).child(playerId)
            .child(CONSTANTS.NAME).set(playerName!)
            .catch((error) => {
              throw new Error(error.message);
            });

          app.database().ref(CONSTANTS.PLAYERS).child(playerId).child("code")
            .set(code)
            .catch((error) => {
              throw new Error(error.message);
            });

          return code;
        });

      return gameCode;
    } else {
      throw new Error("No player provided.");
    }
}

const randomGenerator = () => {
  // https://gist.github.com/6174/6062387
  return Math.random().toString(36).substring(2, 4) + Math.random().toString(36).substring(2, 4);
}

const validateCodeForRoom = async (code: string) => {
  let returnCode;
  
  await app.database().ref(CONSTANTS.ROOMS).child(code).once('value')
    .then((snapshot) => {
      if (snapshot.exists()) {
        returnCode = null;
      } else {
        returnCode = code;
      }
    });

    return returnCode;
}

// https://stackoverflow.com/questions/29020722/recursive-promise-in-javascript
const getCodeForRoom = async (count?: number) => {
    count  = count || 0;
    let code = randomGenerator();

    if (count > 5) {
      throw new Error("Too many attempts to generate code");
    }

    return await new Promise(async (resolve) => {
      resolve(await validateCodeForRoom(code));
    }).then(async (validCode) => {
      return validCode ? code : await getCodeForRoom(count! + 1);
    })
}

export const removePlayer = async (code, userId) => {
  // remove player from room
  await app.database().ref(CONSTANTS.ROOMS).child(code)
    .child(CONSTANTS.PLAYERS).child(userId).remove();
}

export const leaveGame = async (code, userId) => {
  // remove player from room and remove room code from player object
  await app.database().ref(CONSTANTS.ROOMS).child(code)
    .child(CONSTANTS.PLAYERS).child(userId).remove();
  await app.database().ref(CONSTANTS.PLAYERS).child(userId)
    .child(CONSTANTS.CODE).remove();
}

export const deleteGame = async (code, players: Player[]) => {
  let updates = {}

  players.forEach(player => {
    updates[`${player.id}/${CONSTANTS.CODE}`] = null;
  });

  //await app.database().ref(CONSTANTS.PLAYERS).update(updates);
  await app.database().ref(CONSTANTS.ROOMS).child(code).remove();
}

export const removeRoomCodeFromPlayer = async (playerId) => {
  await app.database().ref(CONSTANTS.PLAYERS).child(playerId)
    .child(CONSTANTS.CODE).remove();
}

// Pick teams

export const startGame = async (code) => {
  await app.database().ref(CONSTANTS.ROOMS).child(code).child(CONSTANTS.STATE)
    .set(CONSTANTS.PICK_TEAMS)
    .catch((error) => {
      throw new Error(error.message)
    });
  await app.database().ref(CONSTANTS.ROOMS).child(code).child(CONSTANTS.FULL)
    .set(true)
    .catch((error) => {
      throw new Error(error.message)
    });
  await setRandomTeams(code);
}

export const setRandomTeams = async (code) => {
  await app.database().ref(CONSTANTS.ROOMS).child(code)
    .child(CONSTANTS.PLAYERS).once("value")
    .then((snapshot) => {
      const playerList = snapshot.val();

      let players = Array<Player>();
      for (let id in playerList) {
        const player: Player =
          {
            id: id,
            name: playerList[id].name
          }
        players.push(player);
      }
      return players;
  }).then((players) => {
    const sorted = players.sort(() => 0.5 - Math.random()); // randomly sort players
      
    const halfwayPoint: number = sorted.length / 2;
    const randomRedTeam = sorted.slice(0, halfwayPoint);
    const randomBlueTeam = sorted.slice(halfwayPoint, sorted.length);

    let updates = {};
      
    randomBlueTeam.forEach(player => {
      updates[`${player.id}/${CONSTANTS.TEAM}`] = CONSTANTS.BLUE;
    });

    randomRedTeam.forEach(player => {
      updates[`${player.id}/${CONSTANTS.TEAM}`] = CONSTANTS.RED;
    });

    app.database().ref(CONSTANTS.ROOMS).child(code)
      .child(CONSTANTS.PLAYERS)
      .update(updates);
  })
  .catch((error) => {
    throw new Error(error.message);
  });
}

// Pick Spymaster

export const spyMaster = async (code) => {
  await app.database().ref(CONSTANTS.ROOMS).child(code).child(CONSTANTS.STATE)
    .set("spy-master")
    .catch((error) => {
      throw new Error(error.message)
    });
}

// Set board

export const setBoard = async (code: string) => {
  const boardRef = app.database().ref(CONSTANTS.ROOMS).child(code).child(CONSTANTS.BOARD)
  await boardRef.child(CONSTANTS.GAME_OVER).set(false)

  const updates = {};
  updates[CONSTANTS.CLUE] = "";
  updates[CONSTANTS.GUESSES] = 0;
  updates[CONSTANTS.SELECTED] = -1;
  updates[CONSTANTS.TEAM] = "none";
  updates[CONSTANTS.TYPE] = "none";

  await boardRef.child(CONSTANTS.TURN).set(updates);
}

// Board actions

export const spymasterSubmit = async (code: string, player: Player, clue: string, guesses: number) => {
  // let nextTeamColor;
  // if (player.team! === "red")
  //   nextTeamColor = "blue"
  // else if (player.team! == "blue")
  //   nextTeamColor = red

  const updates = {}
  updates[CONSTANTS.TYPE] = "player";
  updates[CONSTANTS.TEAM] = player.team!;
  updates[CONSTANTS.CLUE] = clue;
  updates[CONSTANTS.GUESSES] = guesses;

  app.database().ref(CONSTANTS.ROOMS).child(code)
    .child(CONSTANTS.BOARD).child(CONSTANTS.TURN)
    .update(updates)
}

export const tileSelectedInDB = async (code, index, turn) => {
  await app.database().ref(CONSTANTS.ROOMS).child(code)
    .child(CONSTANTS.WORDS).child(index).once('value').then((snapshot) => {
      const tile: Tile = snapshot.val()
      switch  (tile.color) {
        case (CONSTANTS.BLACK):
          const winningTeam = turn.team === CONSTANTS.RED ? CONSTANTS.BLUE : CONSTANTS.RED
          gameOver(code, winningTeam);
          break;
        case (CONSTANTS.GREY):
          endTurn(code, turn);
          break;
        case (CONSTANTS.RED):
          if (turn.team === CONSTANTS.BLUE)
          endTurn(code, turn);
          break;
        case (CONSTANTS.BLUE):
          if (turn.team === CONSTANTS.RED)
          endTurn(code, turn);
          break;
      }
    })

  await app.database().ref(CONSTANTS.ROOMS).child(code).child(CONSTANTS.WORDS)
    .child(index).child(CONSTANTS.FLIPPED)
    .set(true);
}

export const gameOver = async (code: string, team: "red" | "blue") => {
  const updates = {};
  updates[CONSTANTS.GAME_OVER] = true;
  updates[CONSTANTS.WINNING_TEAM] = team;

  await app.database().ref(CONSTANTS.ROOMS).child(code)
    .child(CONSTANTS.BOARD)
    .update(updates);
}

export const endTurn = async (code, turn) => {
  let updates = {
    clue: "",
    guesses: 0,
    team: "",
    type: CONSTANTS.SPYMASTER
  }

  const currentTeam = turn.team;
  if (currentTeam === CONSTANTS.RED){
    updates.team = CONSTANTS.BLUE
  } else if (currentTeam === CONSTANTS.BLUE) {
    updates.team = CONSTANTS.RED
  }

  await app.database().ref(CONSTANTS.ROOMS).child(code)
    .child(CONSTANTS.BOARD).child(CONSTANTS.TURN)
    .update(updates);
}

export const selectTile = async (code, index) => {
  const update = {};
  update[CONSTANTS.SELECTED] = index;

  await app.database().ref(CONSTANTS.ROOMS).child(code)
    .child(CONSTANTS.BOARD).child(CONSTANTS.TURN)
    .update(update);
}

// start new game, when current game is over
export const newGame = async (currentCode, newCode) => {
  const boardRef = app.database().ref(CONSTANTS.ROOMS).child(currentCode).child(CONSTANTS.BOARD)
  await boardRef.child(CONSTANTS.NEW_GAME).set(newCode)
}


export const logFeedback = async (userId: string, feedback: string, location: string): Promise<firebase.database.Reference> =>
  app.database().ref(CONSTANTS.FEEDBACK)
    .push(
      {user: userId, feedback: feedback, location: location}
    )