import React from 'react';
import { Player } from '../../models';
import { ListItemText, ListItem, List, ListItemAvatar, Avatar, makeStyles } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { red, blue, green } from "@material-ui/core/colors";
import { RED, BLUE } from '../../constants';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(2),
    },
    red: {
        backgroundColor: red[500],
    },
    blue: {
        backgroundColor: blue[500],
    },
    green: {
        backgroundColor: green[500],
    }
}));

interface PlayerIconProps {
    player: Player;
}

const BoardIcons = ({player}: PlayerIconProps) => {
    const classes = useStyles();

    const getClasses = (player: Player) => {
        let paperClassList = [""]
        if (player.team === RED)
            paperClassList.push(classes.red)
        if (player.team === BLUE)
            paperClassList.push(classes.blue)
        
        return paperClassList.join(' ');
    }

    return (
        <>
            { player.spymaster 
                ?   <Avatar className={getClasses(player)}>
                        <PersonIcon />
                    </Avatar>
                :   <Avatar className={getClasses(player)}>
                        <GroupIcon />
                    </Avatar>
            }
        </>
    )
}

const SpymasterIcons = ({player}: PlayerIconProps) => {
    const classes = useStyles();

    return (
        <>
            { player.spymaster 
                ?   <Avatar className={classes.green}>
                        <CheckIcon />
                    </Avatar>
                :   <Avatar className={classes.red}>
                        <CloseIcon />
                    </Avatar>
            }
        </>
    )
}


interface PlayerListProps {
    players: Player[];
    page: "board" | "spymaster";
}

const BoardPlayerList = ({players, page}: PlayerListProps) => {

    const Icons = ({player}: PlayerIconProps) => {
        if (page === "board")
            return <BoardIcons player={player} />
        else if (page === "spymaster")
            return <SpymasterIcons player={player} />
        else
            return <></>
    }
    

    return (
        <List aria-label="players">
            {players.map((player: Player, i) => (
                <ListItem key={i}>
                    <ListItemAvatar>
                        <Icons player={player} />
                    </ListItemAvatar>
                    <ListItemText primary={player.name}/>
                </ListItem>
            ))}
        </List>
    )
}

export default BoardPlayerList;