import React, { useState } from 'react';
import { TextField, Button, Grid, Divider, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { signInAnonymous, signInWithGoogle } from '../services/auth';
import * as db from '../services/db';
import { User } from 'firebase';

const SignupPage = () => (
    <div>
      <h1>Signup</h1>
      <Signup />
    </div>
  );

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    button: {
        margin: "0.5rem 0"
    }
}));

const Signup = () => {
    const [name, setName] = useState("");
    const [textError, setTextError] = useState("");
    const [error, setError] = useState("");
    const classes = useStyles();
    let history = useHistory();
    const { state } = history.location;

    const changeField = (event) => {
        const name = event.target.value;
        setName(name);
        if (name.trim().length > 0) {
            setTextError("");
        } else {
            setTextError("Name is required")
        }
    }

    const goToNextPage = async (user: User) => {
        if (state) {
            const pathname = state["from"].pathname;
            const path = pathname.split("/");
            // ["", "room", "{code}"]
            if (path[1] === "room") {
                await db.joinRoom(path[2], user!).then(() => {
                    history.push(`room/${path[2]}`);
                }).catch((error) => {
                    history.push("/");
                });
            } else
                history.push(pathname);
        } else {
            history.push("/");
        }
    }

    const formSubmit = (event) => {
        event.preventDefault();
        
        const trimmedName = name.trim();
        if (trimmedName.length > 0) {
            signInAnonymous()
                .then(authUser => {
                    const user = authUser.user;
                    if (user) {
                        user.updateProfile({displayName: trimmedName})
                            .then(async () => {
                                await db.setUserName(user);
                                goToNextPage(user)
                            })
                            .catch(error => {
                                setError(error.message);
                            })
                    }
                })
                .catch(error => {
                    setError(error.message);
                });
        } else {
            setTextError("Name is required")
        }
    }

    const googleSignIn = () => {
        signInWithGoogle()
            .then(async result => {
                const user = result.user;
                if (user) {
                    await db.setUserName(user);
                    goToNextPage(user)
                }
            })
            .catch(error => {
                setError(error.message);
            });
    }

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={5}>
                <h4>Anonymous</h4>
                <form onSubmit={formSubmit}>
                    <TextField
                        id="name"
                        value={name}
                        onChange={changeField}
                        label="Enter your name"
                        variant="outlined"
                        error={ textError.length !== 0 }
                        helperText={textError}
                    />
                    <div>
                        <Button type="submit" variant="outlined" className={classes.button}>Signup</Button>
                    </div>
                </form>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={12} md={5}>
                <Grid>
                    <h4>OR</h4>
                    <Button onClick={googleSignIn} variant="outlined" className={classes.button}>Google</Button>
                </Grid>
            </Grid>
            { error &&
                <Grid item xs={12}>
                    <span>{error}</span>
                </Grid>
            }
        </Grid>
    );
}

export default SignupPage;