import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as CONSTANTS from "../constants";
import { makeStyles, Divider, Grid, Button } from '@material-ui/core';
import Player from '../models/Player';
import * as db from '../services/db';
import app from '../base';
import { UserContext } from '../components/Session';
import { PlayerList } from '../components/Lists';

const useStyles2 = makeStyles({
    root: {
        marginTop: 16,
    },
    button: {
        marginRight: "1rem",
        margin: "0.5rem 0"
    }
});

const MIN_PLAYERS = 2;

const PickTeams = () => {
    let { id } = useParams();
    let history = useHistory();
    const classes = useStyles2();
    const user = useContext(UserContext);
    const [host, setHost] = useState(false);
    const [redTeam , setRedTeam] = useState(Array<Player>());
    const [blueTeam , setBlueTeam] = useState(Array<Player>());
    const [error, setError] = useState("");

    useEffect(() => {
        const roomRef = app.database().ref(CONSTANTS.ROOMS).child(id);

        roomRef.child(CONSTANTS.STATE).on('value', snapshot => {
            const room: string = snapshot.val();

            switch (room) {
                case "spy-master":
                    history.push(`/room/${id}/spy-master`);
                    break;
                case CONSTANTS.BOARD:
                    history.push(`/room/${id}/board`);
                    break;
            }
        });

        roomRef.child(CONSTANTS.PLAYERS).on('value', snapshot => {
            const playerList = snapshot.val();
            let players: Player[] = []

            for (let id in playerList) {
                const player: Player =
                    {
                        id: id,
                        name: playerList[id].name,
                        team: playerList[id].team
                    }
                players.push(player)
            }

            setBlueTeam(players.filter(p => p.team === CONSTANTS.BLUE))
            setRedTeam(players.filter(p => p.team === CONSTANTS.RED))
        });

        // component unmount
        return () => {
            roomRef.child(CONSTANTS.STATE).off();
            roomRef.child(CONSTANTS.PLAYERS).off();
        }
    }, [])

    useEffect(() => {
        const roomRef = app.database().ref(CONSTANTS.ROOMS).child(id);

        roomRef.child(CONSTANTS.HOST).on('value', snapshot => {
            const playerId = user!.uid;
            if (snapshot.val() === playerId) {
                setHost(true);
            }
        });

        // component unmount
        return () => {
            roomRef.child(CONSTANTS.HOST).off();
        }
    }, [user])

    const roomRef = app.database().ref(CONSTANTS.ROOMS).child(id);

    const spymaster = async () => {
        if (blueTeam.length >= MIN_PLAYERS && redTeam.length >= MIN_PLAYERS) {
            try {
                await db.spyMaster(id);
            }
            catch (error) {
                setError(error.message);
            }
        }
    }

    const setRandomTeamsClicked = async () => {
        try {
            await db.setRandomTeams(id);
        }
        catch (error) {
            setError((error.message))
        }
    }

    const pickRedTeam = async () => {
        const playerId = user!.uid;
        
        try {
            // if on blue team, switch to red team
            if (blueTeam.some(e => e.id === playerId)) {
                //await roomRef.child(CONSTANTS.PLAYERS).child(playerId).update({team: "red"})
                await roomRef.child(CONSTANTS.PLAYERS).child(playerId)
                    .child(CONSTANTS.TEAM)
                    .set(CONSTANTS.RED)
            }
        } catch (error) {
            setError(error.message)
        }
        
    }

    const pickBlueTeam = async () => {
        const playerId = user!.uid;

        try {
            // if on red team, switch to blue team
            if (redTeam.some(e => e.id === playerId)) {
                //await roomRef.child(CONSTANTS.PLAYERS).child(playerId).update({team: "blue"})
                await roomRef.child(CONSTANTS.PLAYERS).child(playerId)
                    .child(CONSTANTS.TEAM)
                    .set(CONSTANTS.BLUE)
            }
        } catch (error) {
            setError(error.message)
        }
    }

    let statusMessage;
    if (blueTeam.length >= MIN_PLAYERS && redTeam.length >= MIN_PLAYERS)
        statusMessage = "Waiting on host..."
    else
        statusMessage = "Need a minimum of 2 players on each team to continue."

    let nextPageButton;
    if (blueTeam.length >= MIN_PLAYERS && redTeam.length >= MIN_PLAYERS) {
        nextPageButton = <Button onClick={spymaster} variant="contained" className={classes.button}>Next</Button>
    }

    let pickRedTeamButton;
    if (blueTeam.some(p => p.id === user!.uid)) {
        pickRedTeamButton = 
            <Button onClick={pickRedTeam} variant="outlined">Pick Red Team</Button>
    } else {
        pickRedTeamButton = 
            <Button variant="outlined" disabled>Pick Red Team</Button>
    }

    let pickBlueTeamButton;
    if (redTeam.some(p => p.id === user!.uid)) {
        pickBlueTeamButton = 
            <Button onClick={pickBlueTeam} variant="outlined">Pick Blue Team</Button>
    } else {
        pickBlueTeamButton = 
            <Button variant="outlined" disabled>Pick Blue Team</Button>
    }

    return (
        <>
        <Grid container className={classes.root} justify="space-evenly" wrap="wrap" alignItems="stretch">
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <h4>Red Team</h4>
                    <Grid item>
                        { pickRedTeamButton }
                    </Grid>
                    <PlayerList players={redTeam}/>
                </Grid>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <h4>Blue Team</h4>
                    <Grid item>
                        { pickBlueTeamButton }
                    </Grid>
                    <PlayerList players={blueTeam}/>
                </Grid>
            </Grid>
        </Grid>
        <Grid container justify="flex-end" className={classes.root} >
        { host && 
            <Grid item xs={12}>
                <Button onClick={setRandomTeamsClicked} variant="outlined" className={classes.button}>Randomize Teams</Button>
                { nextPageButton }
            </Grid>
        }
        { !host &&
            <Grid item xs={12}><span>{statusMessage}</span></Grid>
        }
        { error &&
            <Grid item xs={12}><span>{error}</span></Grid>
        }
        </Grid>
        </>
    )
}

export default PickTeams;