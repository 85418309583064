import React from 'react';
import { Player } from '../../models';
import { ListItemText, ListItem, List } from '@material-ui/core';

interface PlayerListProps {
    players: Player[];
}

const PlayerList = ({players}: PlayerListProps) => {
    return (
        <List aria-label="players">
            {players.map((player: Player, i) => (
                <ListItem key={i}>
                    <ListItemText primary={player.name} />
                </ListItem>
            ))}
        </List>
    )
}

export default PlayerList;